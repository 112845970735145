<template>
  <v-app>
  	<section class="section feather-bg-img">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 mb-3">
          <div class="text-justify mb-5">
            <h2 class="font-weight-semibold line-height-1_4 mb-4 text-center">
              <b>Privacy Policy</b>
            </h2> 
            <p class="font-size-24">
              <b>Privacy Statement Sigeris high school </b> 
            </p>
            <p class="font-size-16">
              We are Sigeris high school. We offer an application(including a mobile app) (the “App“) to secondary education institutions, which allows their students and parents to connect in a meaningful manner and to get access to relevant information about student marks and assiduity in their respective classrooms. 
              </p> 
              <p class="font-size-16">
				When you use Sigeris high school, you are entrusting us with your information (personal data). We understand this is a big responsibility and work hard to protect your data. This Privacy Statement is meant to help you understand what personal data we collect, why we collect it,and what your rights are under applicable laws.
				</p>
				<p class="font-size-16">
				Sigeris high school is established in Cameroon, and is registered in the Cameroon Chamber of Commerce under number RC/BFM/2022/B/118. You can find our contact details at the bottom of this Privacy Statement. 
            </p>
            <ol type="I">
			  <li>Our processing activities</li>
			    <ol>
			      <li>Creating your profile in the App</li>
			      	<p>You can create an account in the App by using the access details (e.g. telephone number). These access details are linked to your secondary education institution.

					Your account is automatically created when your admission is validated by your school administration and with the telephone number provided,you can use it to login.

					After you log in, you just browse your performace and assiduity details.

					If your secondary education institution uses a single-sign-on service (a login option specifically for that institution) some details will be prefilled out in your account on the basis of information the institution has about you. Such as your name, email address, date of birth, city of origin, campus, degree and study (discipline and year). Some of these details cannot be altered, if your institution decides to keep them unaltered.

					Your secondary education institution may use your personal data to verify your compliance with the terms of service.</p>
		          <li>Connecting and communicating with other users</li>
		          	<p>After you have made a profile,any parent will be able to see your basic details in the App, e.g. yourname, country and city you are from and your study. 
		          	If you find certain content in the App inappropriate, you can flag this content and you can also flag your school administration. </p>
		          
		          <li>Content management / moderating</li>
		          	<p>To ensure a safe and healthy community within the App, we may moderate content that is added to the App. We may do this if we flag inappropriate content or if a student or parent does so. 
					An institution may also ask us to remove a certain student from the App, e.g. because they are no longer enlisted at the institution.</p>
		          
		          <li>Communicating with you</li>
		          	<p>We can use your contactdetails to interact with you directly. For example, we may send you a notification about new functionality in the App or to let you know you have unread messages or connection requests. Or we may notify you about changes in this Privacy Statement.
					</p>
		          <li>Access by secondary education institution</li>
		          <p>Secondary education institutions have their own administrator account for the App. We use the relevant administrator’s personal data (log in details, usage details) to offer the administrator account to the secondary education institutions, to verify their access to the administrator account and verify their compliance with the terms of service.
		          	Secondary education institutions can use a dashboard that shows them aggregated information about their students using the App, e.g. how many students are from a certain country, how many apply for a certain study and how likely it is that they will apply for a study.

					The dashboard also shows them personal data about the students such as name, email address, country of origin, accountstatus, likelihood they will apply (“Sigeris high school score“). The institutions use the information in the dashboard for their own purposes, we process those personal data as a processor. Please see the relevant institution’s privacy statement for more information about their data processing activities. The institution will generally use the personal data in the dashboard to contact you (by email or telephone) about applying for a study there.</p>
		          <li>Analyzing the use(rs) of the app</li>
		          	<p>We also process your data to ensure the App is working as intended and to understand how the App is used.
					We may do this by asking you to rate us. Your answers will be disconnected from you profile and anonymized.We use ratings you provide us with to ensure our services are working as intended and to understand how our services are used.
					We will also aggregate usage data to get insight into how the App is being used, for example, frequency and duration of your use of the App.</p>
		          <li>Ensuring the safety of our infrastructure and technical resources and the safety of your account</li>
		          	<p>Sigeris high school makes every effort to ensure that the risks of fraudulent access to Sigeris high schools system are minimized. To this extent, Sigeris high school uses your personal data to detect prevent and respond to fraud, abuse, security risks, and technical issues.</p>
		          <li>Complying with legal disclosure requests</li>
		          	<p>We may become obliged to comply with a legal obligation imposed upon us which obliges us to disclose your personal data. We will try to minimize such disclosure to only that which is reasonably necessary to comply with the legal obligation and, where legally permitted,to provide you with notice of such disclosure.</p>
			    </ol>
			  </li>
			  <li>Legal grounds</li>
			  	<ol>
			  		<p>In this section we explain the legal grounds on the basis of which we process your personal data.</p>
			  		<p>The legal grounds for processing your personal data are:</p>
			  		<p>That we need your personal data to be able to conclude and perform the agreement with you;
				    That we need your personal data for our legitimate interests and that of the secondary education institutions and the students, to be able to offer our services to the secondary education institutions and the students, to offer a safe community (moderation), to secure the App, to communicate with you and more generally to run our business;
				    That we may have to disclose your personal data to a third person or party on the basis of a specific request, if such person or party has a legitimate interest to receive such data and this does not outweigh your interests, e.g. if you committed an act of tort and the affected person requests for personal data to evidence this;
				    We may also have to process (disclose) your personal data to comply with a legal obligation. This relates for example to having to disclose your data to judicial authorities, for example if you have committed a criminal act;
				    In specific situations, for example if we want to send you commercial emails or SMS, we ask your consent.  </p>
			  	</ol>
			  <li>Processors and third parties</li>
			  	<ol>
			  		<p>In this section we explain with what third parties we share your personal data.</p>
			  		<p>We use service providers who process your personal data on our behalf: our hosting provider who stores the data, email providers who host email communication, the chat hosting provider and a software development company. As indicated above, we also share limited personal data with your institution and may be obliged to share personal data with third parties, including government institutions.</p>
			  		<p>Our service providers store the data within the European Economic Area (EEA) but our software development service provider has (remote) access to the data from Cameroon. We have concluded standard contractual clauses with this provider, which obliges them to ensure an adequate level of protection of your personal data.</p>
			  	</ol>
			  
			  <li>For how long do we store your personal data?</li>
			  	<ol>
			  		<p>
			  			In this section we explain how long we store your personal data.
			  			The students’ data in the App are, by default, stored for a period of five years after the last login. If you have received access details to access the App but have not registered for an account in the App, your basic details (e.g. name, email address) will be removed from our system after a certain period, which period can be specified by the institution.
			  			The administrators’ data are stored for as long as the administrator works for the secondary education institution and uses the App and a period of 2 years after the administrator has left the institution’s service.
			  		</p>
			  	</ol>
			  <li>What are your rights?</li>
			  	<ol>
			  		<p>In this section we explain what your rights are under the GDPR.
			  		We find it important that you are aware of your rights and how to use them. Under the General Data Protection Regulation (GDPR) you have several rights. In summary, those include rights to:
			  		<ul class="rights">
				  		<li>Access to and a copy of your personal data;</li>
				  		<li>Require us to correct incorrect personal data – please note that you can update most of your information in your account through your administrator;</li>
				  		<li>Delete your personal data;   </li> 
				  		<li>Receive the personal data concerning you which you have provided to us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations – this applies if your personal data are processed electronically on the basis of consent or to perform the agreement with you;
				  		Object to processing of your personal, including for direct marketing purposes;</li>
				  		<li>Restrict our processing of your personal data;</li>
				  		<li>Withdraw your consent with regard to the processing of data for which you have given us your consent.</li>
			  		</ul>
			  		These rights are not absolute,in some cases we have the right to not comply with your request. We will respond to your GDPR request within the applicable timeframes.

			  		If you have a complaint about the way in which we handle your personal data, we will be happy to resolve it with you, please contact us using the contact details below.

			  		You also have, at any time,the right to lodge a complaint with the relevant authority.</p>
			  	</ol>
			  <li>Contact</li>
			  	<p>If you have any questions or if you would like to exercise any of the mentioned rights, please contact us using our contact details below and provide us with enough information to identify you. Also, let us know the information to which your request relates.
			  	</p>
			  	<p><b>Company name:</b> SIGERIS SARL</p>
			  	<p><b>Email address:</b> sigerishs@gmail.com</p>
			  
			  <li>Alterations of this Privacy Statement</li>
			  	<p>We may have to update this Privacy Statement from time to time. In case of important changes, we will notify you thereof, for example through our website.</p>
			  
			</ol>
			<p><b>Date: 20 September 2023</b></p>
          </div>
        </div>
      </div>
      </div>
  </section>
    <div class="footer-alt py-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-center">
                        <p class="text-white-50 font-size-15 mb-0 serif">
                            Copyright &copy; {{currentYear}} SIGERIS SARL&trade;
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </v-app>
</template>

<script>
  export default {
    data () {
      return {
        fab: false,
        currentYear: new Date().getFullYear(),  
        
      }
    },

  }

  </script>


<style type="text/css">
	li{
		font-weight: bold;
		font-size: 24px;
	}
	.rights li{
		font-weight:normal;
		font-size: 16px;
	}
</style>