<template>
  <v-app>
   <router-view/>
  </v-app>
</template>

<script>

  import router from './router/index.js';
  import Privacy from './views/Privacy.vue';
  export default {
    created() {
      router.addRoute({
        component: Privacy,
        name: "Privacy",
        path: "/Privacy"
      })
    },
    data () {
      return {
        fab: false,
        currentYear: new Date().getFullYear(),  
        
      }
    },

    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
          const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
    }
  }

</script>



<style>
  @import './views/components/plugins/style.css';
  @import "./views/components/plugins/main.css";
</style>


